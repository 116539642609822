import React from "react";
import { useSelector } from "react-redux";
import {
  ExamSubjectI,
  MarkSheetI,
  MarksLedgerI,
} from "../../../rtk/features/exam/marksLedger/marksLedger";
import { RootState } from "../../../rtk/store";
import { RootStore } from "../../../store";
import {
  Sheet,
  SheetWrapper,
  Header,
  StudentInfo,
  Main,
  Footer,
  LogoContainer,
  SchoolInfoDiv,
  KeyValue,
  TableFooter,
  BorderedTableData,
  EmptyRow,
  FooterTop,
  FooterBottom,
  FooterDate,
  DaysSection,
  SchoolSeal,
  ContactInfo,
  SloganText,
  SchoolNameText,
  SchoolAddressText,
  ExamNameText,
  GradeSheetText,
  ClassNameText,
} from "./gradesheetSingleFinalStyles";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import { GradeTypeI } from "../../../actions/Examination/Grade/GradeActionTypes";

interface GeneralInfoI {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
}

interface PropsI {
  schoolInfo: {
    name: string;
    address: string;
    phone: string;
    logo: string;
  };
  gradeInfo: {
    grade: string;
    totalStudents: number;
  } | null;
  subjects: ExamSubjectI[];
  marksheet: MarksLedgerI;
  grades: GradeTypeI[];
  examName: string;
}

const GradeSheetSingleFinal = (props: PropsI) => {
  const getMarksBySubject = (subjectMarksInfo: MarkSheetI) => {
    const subjectInfo = props.subjects.find(
      (el) => el.id === subjectMarksInfo.subject_id
    );

    if (subjectInfo) {
      return {
        subjectName: subjectInfo.subject__name,
        creditHourTheory: subjectMarksInfo.credit_hour_theory,
        creditHourPractical: subjectMarksInfo.credit_hour_practical,
        theoryGpa: subjectMarksInfo.theory_obtain_gpa,
        practicalGpa: subjectMarksInfo.practical_obtain_gpa,
        theory: subjectMarksInfo.obtain_grade_theory || "NG",
        practical: subjectMarksInfo.obtain_grade_practical || "NG",
        totalGrade: subjectMarksInfo.average_grade_name || "NG",
        gpa: subjectMarksInfo.average_grade_point || "0",
        remarks: subjectMarksInfo.remarks || "",
      };
    }

    return {
      subjectName: "-",
      creditHourTheory: "-",
      creditHourPractical: "-",
      theoryGpa: "-",
      practicalGpa: "-",
      theory: "-",
      practical: "-",
      totalGrade: "-",
      gpa: "-",
      remarks: "",
    };
  };

  return (
    <Sheet>
      <SheetWrapper>
        <Header>
          <LogoContainer>
            <img src={props.schoolInfo.logo || ""} alt="logo" />
          </LogoContainer>
          <SchoolNameText>{props.schoolInfo.name}</SchoolNameText>
          <SchoolAddressText>{props.schoolInfo.address}</SchoolAddressText>
          <GradeSheetText>Grade Sheet</GradeSheetText>
          <ExamNameText>{props.examName}</ExamNameText>
        </Header>
        <StudentInfo>
          <p>
            Grades secured by: {props.marksheet.student_first_name}{" "}
            {props.marksheet.student_last_name} Child of{" "}
            {props.marksheet.student_parent_first_name}{" "}
            {props.marksheet.student_parent_last_name}. Grade:{" "}
            {props.gradeInfo?.grade} Roll No:{" "}
            {props.marksheet.student_roll_number} In the {props.examName} Are
            Given Below
          </p>
        </StudentInfo>
        <Main>
          <table>
            <thead>
              <tr>
                <th>S.N</th>
                <th>SUBJECTS</th>
                <th>
                  CREDIT <br /> HOURS
                </th>
                <th>
                  GRADE <br /> POINT
                </th>
                <th>GRADE</th>
                <th>
                  FINAL <br /> GRADE
                </th>
                <th>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {props.marksheet.obtain_subject_marks.map((el, index) => (
                <tr key={index + 1}>
                  <td align="center">{index + 1}</td>
                  <td align="left">
                    <p>{getMarksBySubject(el).subjectName} TH</p>
                    <p>{getMarksBySubject(el).subjectName} PR</p>
                  </td>
                  <td align="center">
                    <p>{getMarksBySubject(el).creditHourTheory}</p>
                    <p>{getMarksBySubject(el).creditHourPractical}</p>
                  </td>
                  <td align="center">
                    <p>{getMarksBySubject(el).theoryGpa}</p>
                    <p>{getMarksBySubject(el).practicalGpa}</p>
                  </td>
                  <td align="center">
                    <p>{getMarksBySubject(el).theory}</p>
                    <p>{getMarksBySubject(el).practical}</p>
                  </td>
                  <td align="center">{getMarksBySubject(el).totalGrade}</td>
                  <td align="center">{getMarksBySubject(el).remarks}</td>
                </tr>
              ))}
              <tr>
                <BorderedTableData align="center" colSpan={2}>
                  TOTAL CREDIT HOURS
                </BorderedTableData>
                <BorderedTableData align="center" colSpan={1}>
                  {props.marksheet.total_credit_hour || "0"}
                </BorderedTableData>
                <BorderedTableData align="left" colSpan={4}>
                  GRADE POINT AVERAGE: {props.marksheet.obtain_gpa || "0"}
                </BorderedTableData>
              </tr>
            </tbody>
          </table>
        </Main>
        <Footer>
          <FooterTop>
            <div>
              <table>
                <thead>
                  <tr>
                    <th align="left">S.N</th>
                    <th align="left">Percentage Interval</th>
                    <th align="left">Grade</th>
                    <th align="left">Desription</th>
                    <th align="left">Grade Point</th>
                  </tr>
                </thead>
                <tbody>
                  {props.grades.map((el, index) => (
                    <tr key={el.id}>
                      <td align="left">{index + 1}</td>
                      <td align="left">
                        {el.marks_from} To {el.marks_to}
                      </td>
                      <td align="left">{el.grade_name}</td>
                      <td align="left">{el.description!}</td>
                      <td align="left">{el.grade_point}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </FooterTop>
          <FooterBottom>
            <FooterDate>
              <span>{dateConverterAdToBs(new Date().toISOString())}</span>
              <span>Date</span>
            </FooterDate>
            <span>Class Teacher</span>
            <span>Principal</span>
          </FooterBottom>
        </Footer>
      </SheetWrapper>
    </Sheet>
  );
};

export default GradeSheetSingleFinal;

import multipleBrandsConfig from "./multipleBrandsConfig";

multipleBrandsConfig();
let tenantURL = "https://school.hamro.academy";

switch (process.env.REACT_APP_ENV) {
  case "dev":
    tenantURL = "https://staging.hamro.academy";
    break;

  case "staging":
    tenantURL = "https://staging.hamro.academy";
    break;

  case "prod":
    tenantURL = "https://school.hamro.academy";
    break;

  case "ict":
    tenantURL = "https://ict1.hamro.academy";
    break;

  default:
    tenantURL = "https://staging.hamro.academy";
    break;
}

export const TEST_SITE_URL = "https://school.hamro.academy";
export const TEST_ADMIN_API_URL = "https://staging.hamro.academy/api/v1";
export const TEST_TEACHER_API_URL =
  "https://school.hamro.academy/api/teacher/v1";
export const TEST_PARENT_API_URL = "https://school.hamro.academy/api/parent/v1";
export const TEST_STUDENT_API_URL =
  "https://school.hamro.academy/api/student/v1";
export const TEST_URL = "https://school.hamro.academy/";
export const LOCALURL = "http://localhost:3000/";

const isDevMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const SITE_URL = isDevMode ? TEST_SITE_URL : tenantURL;

export const ADMIN_API_URL = isDevMode
  ? TEST_ADMIN_API_URL
  : `${tenantURL}/api/v1`;

export const TEACHER_API_URL = isDevMode
  ? TEST_TEACHER_API_URL
  : `${tenantURL}/api/teacher/v1`;
export const PARENT_API_URL = isDevMode
  ? TEST_PARENT_API_URL
  : `${tenantURL}/api/parent/v1`;
export const STUDENT_API_URL = isDevMode
  ? TEST_STUDENT_API_URL
  : `${tenantURL}/api/student/v1`;

export const URL = tenantURL;

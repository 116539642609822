import React from "react";
import { useSelector } from "react-redux";
import {
  CombinedStudentMarksI,
  ExamSubjectI,
  MarksLedgerI,
} from "../../../rtk/features/exam/marksLedger/marksLedger";
import { RootState } from "../../../rtk/store";
import { RootStore } from "../../../store";
import {
  Sheet,
  SheetWrapper,
  Header,
  StudentInfo,
  Main,
  Footer,
  LogoContainer,
  SchoolInfoDiv,
  KeyValue,
  TableFooter,
  BorderedTableData,
  EmptyRow,
  FooterTop,
  FooterBottom,
  FooterDate,
  Watermark,
  ExamTitle,
  DateSection,
} from "./MarksheetStyles";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import { GradeTypeI } from "../../../actions/Examination/Grade/GradeActionTypes";

interface GeneralInfoI {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
}

interface PropsI {
  schoolInfo: {
    name: string;
    logo: string;
    address: string;
    phone: string;
  };
  gradeInfo: {
    grade: string;
    totalStudents: number;
  } | null;
  subjects: {
    id: string;
    name: string;
    hasPractical: boolean;
    markingType: number;
    creditHour: string;
  }[];
  exams: {
    id: string;
    name: string;
    weightage: number;
  }[];
  marksheet: CombinedStudentMarksI;
  grades: GradeTypeI[];
}

const GradeSheetCombined = (props: PropsI) => {
  const returnTerminalMarks = ({
    subjectId,
    isPractical,
  }: {
    subjectId: string;
    isPractical: boolean;
  }) => {
    const result: JSX.Element[] = [];

    props.exams.forEach((el) => {
      const targetSubject = props.marksheet.obtain_subject_marks.find(
        (i) => i.subject_id === subjectId
      );

      if (targetSubject) {
        const targetExam = targetSubject.terms_wise_marks.find(
          (i) => i.exam_info_id === el.id
        );

        if (targetExam) {
          isPractical
            ? result.push(
                <td align="center">{targetExam.practical_obtain_marks}</td>
              )
            : result.push(
                <td align="center">{targetExam.theory_obtain_marks}</td>
              );
        } else {
          result.push(<td align="center">-</td>);
        }
      } else {
        result.push(<td align="center">-</td>);
      }
    });

    return result;
  };

  const returnSubjectGrades = (id: string) => {
    const targetSubject = props.marksheet.obtain_subject_marks.find(
      (i) => i.subject_id === id
    );

    if (targetSubject) {
      return {
        gradeTh: targetSubject.theory_obtain_grade,
        gradePr: targetSubject.practical_obtain_grade,
        gpaTh: targetSubject.theory_obtain_gpa,
        gpaPr: targetSubject.practical_obtain_gpa,
        remarks: targetSubject.remarks,
      };
    }

    return {
      gradeTh: "NG",
      gradePr: "NG",
      gpaTh: "0",
      gpaPr: "0",
      remarks: "NG",
    };
  };

  return (
    <Sheet>
      <SheetWrapper>
        <Watermark src={props.schoolInfo.logo} />
        <Header>
          <LogoContainer>
            <img src={props.schoolInfo.logo} alt="logo" />
          </LogoContainer>
          <SchoolInfoDiv>
            <h2>{props.schoolInfo.name}</h2>
            <p>{props.schoolInfo.address}</p>
          </SchoolInfoDiv>
        </Header>
        <ExamTitle></ExamTitle>
        <StudentInfo>
          <div>
            <KeyValue>
              <span>Name of the Student:</span>
              <span>
                {props.marksheet.student_first_name}{" "}
                {props.marksheet.student_last_name}
              </span>
            </KeyValue>
            {/* <KeyValue>
              <span>Roll No</span>
              <span>N/A</span>
            </KeyValue> */}
            {/* <KeyValue>
              <span>Date of Birth (BS)</span> 
              <span>{"N/A"}</span>
            </KeyValue> */}
            {/* <KeyValue>
              <span>Father</span>
              <span>Jack Doe</span>
            </KeyValue>
            <KeyValue>
              <span>Mobile/Registration ID</span>
              <span>{"N/A"}</span>
            </KeyValue> */}
          </div>
          <div>
            <KeyValue>
              <span>Class:</span>
              <span>{props.marksheet.grade_name || ""}</span>
            </KeyValue>
            {/* <KeyValue>
              <span>Date of Birth (AD)</span>
              <span>{"N/A"}</span>
            </KeyValue>
            <KeyValue>
              <span>Mother</span>
              <span>Julia Doe</span>
            </KeyValue>
            <KeyValue>
              <span>Student ID</span>
              <span>00153523535</span>
            </KeyValue> */}
          </div>
        </StudentInfo>
        <Main>
          <table>
            <thead>
              <tr>
                <th align="left">SUBJECTS</th>
                <th>
                  CREDIT <br /> HOUR
                </th>
                {props.exams.map((el, index) => (
                  <th key={index + 1}>
                    {el.name} <br /> {el.weightage}%{" "}
                  </th>
                ))}
                <th>
                  FINAL <br /> GRADE
                </th>
                <th>
                  GRADE <br /> POINT
                </th>
                <th>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {props.subjects.map((el, index) => {
                if (el.hasPractical) {
                  return (
                    <>
                      <tr>
                        <td align="left">{el.name}</td>
                        <td align="center">{el.creditHour}</td>
                        {returnTerminalMarks({
                          subjectId: el.id,
                          isPractical: false,
                        })}
                        <td align="center">
                          {returnSubjectGrades(el.id).gradeTh}
                        </td>
                        <td align="center">
                          {returnSubjectGrades(el.id).gpaTh}
                        </td>
                        <td align="center">
                          {returnSubjectGrades(el.id).remarks}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">{el.name} PR</td>
                        <td align="center">4</td>
                        {returnTerminalMarks({
                          subjectId: el.id,
                          isPractical: true,
                        })}
                        <td align="center">
                          {returnSubjectGrades(el.id).gradePr}
                        </td>
                        <td align="center">
                          {returnSubjectGrades(el.id).gpaPr}
                        </td>
                        <td align="center">
                          {returnSubjectGrades(el.id).remarks}
                        </td>
                      </tr>
                    </>
                  );
                } else {
                  return (
                    <tr key={index + 1}>
                      <td align="left">{el.name}</td>
                      <td align="center">{el.creditHour}</td>
                      {returnTerminalMarks({
                        subjectId: el.id,
                        isPractical: false,
                      })}
                      <td align="center">
                        {returnSubjectGrades(el.id).gradeTh}
                      </td>
                      <td align="center">{returnSubjectGrades(el.id).gpaTh}</td>
                      <td align="center">
                        {returnSubjectGrades(el.id).remarks}
                      </td>
                    </tr>
                  );
                }
              })}
              {/* <EmptyRow>
                {props.exams.map((i, j) => (
                  <td key={j + 1}></td>
                ))}
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </EmptyRow> */}
              <tr>
                <BorderedTableData
                  align="left"
                  colSpan={props.exams.length + 3}
                >
                  Grade Point Average
                </BorderedTableData>
                {/* <BorderedTableData align="center">
                  {props.marksheet.obtain_grade || "NG"}
                </BorderedTableData> */}
                <BorderedTableData align="center">
                  {props.marksheet.obtain_gpa || "0"}
                </BorderedTableData>
                <BorderedTableData align="center">
                  {props.marksheet.remarks}
                </BorderedTableData>
              </tr>
              <tr>
                <BorderedTableData
                  align="left"
                  colSpan={props.exams.length + 5}
                >
                  Result: <span style={{ marginLeft: "50px" }}>Promoted</span>
                </BorderedTableData>
              </tr>
            </tbody>
          </table>
        </Main>
        <Footer>
          <FooterTop>
            <div>
              <table>
                <thead>
                  <tr>
                    <th align="left">Percentage Interval</th>
                    <th align="left">Grade</th>
                    <th align="left">Grade Point</th>
                    <th align="left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {props.grades.map((el) => (
                    <tr key={el.id}>
                      <td align="left">
                        {el.marks_from} To {el.marks_to}
                      </td>
                      <td align="left">{el.grade_name}</td>
                      <td align="left">{el.grade_point}</td>
                      <td align="left">{el.description!}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div></div>
          </FooterTop>
          <FooterBottom>
            <span>Class Teacher</span>
            <DateSection>
              <span>Date</span>
              <p>2079-12-30</p>
            </DateSection>
            <span>Principal</span>
          </FooterBottom>
          {/* <FooterDate>
            ISSUED DATE: {dateConverterAdToBs(new Date().toISOString())}
          </FooterDate> */}
        </Footer>
      </SheetWrapper>
    </Sheet>
  );
};

export default GradeSheetCombined;
